<template>
    <div class="main" :style="{ backgroundImage: `url(${playerDetailsBg})` }">
        <div class="injury_content">
            <!-- 导航 -->
            <page-head :src="require('@/assets/images/common/nav-back-black.svg')" class="nav_back"/>
            <!-- 左侧 -->
            <div class="injury_left">
                <people-header :list="peopleData" peopleType="injury"/>
                <injuryMap :list="svgData"/>
            </div>
            <!-- 右侧 -->
            <div class="injury_right">
                <div class="injury_list">
                    <div class="card_item" v-for="item in injuryData.showList" :key="item.id" @click="cardClick(item)">
                       <div class="injury_data" :class="item.type === 'recovery' ? 'recovery' : 'injury'">
                           <p class="injury_position" v-if="item.type !== 'recovery'">{{ item.position ? item.position : '--' }}</p>
                           <p class="injury_position" v-else>恢复治疗</p>
                           <p class="injury_date">
                               <span class="label">{{ item.type === 'recovery' ? '治疗日期' : '受伤日期'}}</span>
                               <span class="value">{{ item.date }}</span>
                           </p>
                           <p class="injury_severity" v-if="item.type !== 'recovery'">
                               <span class="label">严重程度</span>
                               <span style="display: inline-block"><injuryGrade :grade="item.severity"></injuryGrade></span>
                           </p>
                       </div>
                        <p class="injury_updater">
                            <span class="avatar"><img :src="item.updater_avatar" alt=""></span>
                            <span class="name">{{ item.updater_name }}</span>
                            <span class="date">{{item.create_time}}</span>
                        </p>
                    </div>
                </div>
                <!-- 翻页 -->
                <pagination-com
                        v-if="serverData.length > query.pageSize"
                        class="pagination"
                        :styleNum="{ color: '#787885', opacity: '1' }"
                        :current-page="injuryData.page"
                        :total-page="injuryData.totalPage"
                        @changePage="changePage"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {useRoute, useRouter} from "vue-router";
import {defineComponent, onMounted, getCurrentInstance, toRefs, reactive, watch} from 'vue';
import injuryMap from './components/injuryMap.vue';
import injuryList from './components/injuryList.vue';
import injuryGrade from './components/injuryGrade.vue';
import {useStore} from "vuex";

export default defineComponent({
    components: {
        injuryMap,
        injuryList,
        injuryGrade
    },
    setup() {
        const route = useRoute()
        const router = useRouter()
        const {proxy} = getCurrentInstance();
        const store = useStore();
        const {playerDetailsBg} = proxy.$Config.UI_CONFIG
        const state = reactive({
            playerDetailsBg,
            playerId: route.query.id,
            peopleData: {},
            svgData: [[], [], []],
            injuryData: [],
            query: {
                pageNum: 1,
                pageSize: 6
            },
            serverData: [],
            defaultText: '-'
        });

        watch(() => store.state.identifyData, (val, old) => {
            // console.log(val, old);
            if (!val.type) return false;
            const params = {
                ...val,
                ...state.injuryData,
            }
            proxy.$utils.identifyFn({
                type: val.type,
                data: params,
                changePage: changePage,
            })
        })
        // 球员信息
        const getDetailsData = async () => {
            const params = {
                id: state.playerId, // 球员id
                dynamic: false
            }
            const {code, data} = await proxy.$server.getUserMsg(params);
            if (code === 200) {
                state.peopleData = data;
            }
        };

        // 伤病
        const getInjuryDetail = async () => {
            const params = {
                player_ids: [state.playerId], // 球员id
            }
            const {code, data} = await proxy.$server.injuryList(params);
            if (code === 200) {
                // 获取所有卡片的svg图
                let svg1 = []; // 第一张svg
                let svg2 = []; // 第二张svg
                let svg3 = []; // 第三张svg
                data.map(item => {
                    let injurySvg = JSON.parse(item.injury_svg)
                    injurySvg[0].length ? svg1.push(...injurySvg[0]) : '';
                    injurySvg[1].length ? svg2.push(...injurySvg[1]) : '';
                    injurySvg[2].length ? svg3.push(...injurySvg[2]) : '';
                })
                const { pageNum, pageSize } = state.query;
                state.serverData = data;
                state.injuryData = proxy.$utils.paginationFn(data, pageNum, pageSize);
                state.svgData = [svg1, svg2, svg3]
            }
        };
        const cardClick = (row) => {
            router.push({
                path: '/injuryReport/details',
                query: {
                    id: state.playerId,
                    injuryId: row.id
                }
            })
        }
        const changePage = (page) => {
            const {pageSize} = state.query;
            state.injuryData = proxy.$utils.paginationFn(state.serverData, page, pageSize);
        }

        onMounted(() => {
            getDetailsData()
            getInjuryDetail()
        })

        return {
            changePage,
            cardClick,
            ...toRefs(state),
        }
    },
})
</script>

<style scoped lang="scss">
.main {
    width: 100%;
    height: 100%;
    background-size: 100% auto;
    position: relative;
    box-sizing: border-box;
    padding: 60px 40px;

    .injury_content {
        position: relative;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        height: 100%;
        border-radius: 20px;
        background: url("../../assets/images/common/injury-head-bg.png") no-repeat left top;
        background-size: 100% 300px;
        background-color: #FFFFFF;

        /*返回按钮*/
        .nav_back {
            position: absolute;
            top: 32px;
            left: 32px;
            z-index: 3;
            padding: 0;
        }

        .injury_left {
            flex: 1;
            margin-right: 44px;
        }

        .injury_right {
            width: 970px;
            padding-top: 75px;
            box-sizing: border-box;
            position: relative;

            .injury_list {
                display: flex;
                flex-flow: wrap;
            }

            .card_item {
                width: 405px;
                height: 230px;
                border-radius: 8px;
                border: 1px solid #F6F6F6;
                overflow: hidden;
                margin-left: 24px;
                margin-bottom: 24px;
                color: #fff;

                .injury_data {
                    padding: 0 15px;
                    box-sizing: border-box;
                    background-color: #F95C51;
                    background-image: url("~@/assets/images/injury/injury_card.svg");
                    background-size: 100% auto;
                    background-repeat: no-repeat;
                    background-position: top;
                    height: 164px;
                    &.recovery {
                        background-color: #00CCC0;
                    }
                }

                .injury_position {
                    padding-top: 14px;
                    font-size: 28px;
                    border-bottom: 1px rgba(255, 255, 255, 0.12) dashed;
                    padding-bottom: 11px;
                    width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-family: PingFangMedium;
                }

                .injury_date {
                    padding-top: 12px;
                    padding-bottom: 12px;
                    .value {
                        font-family: akrobatBold;
                    }
                }

                .label {
                    color: rgba(255, 255, 255, .6);
                    font-size: 20px;
                    margin-right: 16px;
                }

                .value {
                    font-size: 24px;
                }

                .injury_updater {
                    background-color: #fff;
                    color: #9C9C9C;
                    padding-left: 15px;
                    padding-right: 15px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    padding-top: 12px;
                    position: relative;

                    .name {
                        font-size: 22px;
                        font-family: PingFangMedium;
                        margin-left: 12px;
                    }

                    .avatar {
                        width: 38.209px;
                        height: 38.209px;
                        border-radius: 30px;
                        display: inline-block;
                        overflow: hidden;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .date {
                        font-size: 24px;
                        font-family: akrobatRegular;
                        position: absolute;
                        right: 15px;
                    }
                }
            }
        }
    }
}

.pagination {
    position: absolute;
    bottom: 24px;
    margin-left: 275px;
    margin-top: 44px;
}

::v-deep .pageBtn {
    background: #B7B7BF !important;
    height: 56px !important;
    line-height: 56px !important;

    &.disable {
        opacity: 1 !important;
        background: #F3F5F9 !important;
        color: #B7B7BF !important;
    }
}
</style>
